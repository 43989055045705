<script>
import ReturnsForm from './ReturnsForm'

export default {
  extends: ReturnsForm,

  data() {
    return {
      isNew: false
    }
  },

  computed: {
    additionalText() {
      return this.form.attention === 1 ? 'Подтвердить изменения' : ''
    }
  },

  created() {
    this.getReturn(this.$route.params.id).then(response => {
      this.form = response

      this.handleNetworkInput()
    })
  },

  methods: {
    onSubmit() {
      this.updateReturn(this.form.id, this.form)
        .then(() => {
          this.successSubmit({ message: 'Заявка успешно изменена', routeName: 'returns' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
